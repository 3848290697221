import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/docs/docs/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "deep-links-to-the-entur-app"
    }}>{`Deep Links to the Entur App`}</h1>
    <p>{`The Entur app can automatically open some `}<em parentName="p">{`Universal Links`}</em>{` in the app. This article explains how you can create such links.`}</p>
    <p>{`If the app is not installed, or if opened on a computer, the link will open on the entur.no website, performing the same operation.`}</p>
    <h2 {...{
      "id": "linking-to-a-stop-place"
    }}>{`Linking to a Stop Place`}</h2>
    <p>{`This link takes the user to the departure board for a specified StopPlace, where the departures for this stop place can be found.`}</p>
    <p>{`It takes one query parameter `}<inlineCode parentName="p">{`id`}</inlineCode>{`, which is an NSR Stop Place ID. You can find your ID using the `}<a parentName="p" {...{
        "href": "/pages-geocoder-intro"
      }}>{`Geocoder API here`}</a>{`.`}</p>
    <h3 {...{
      "id": "example-oslo-s"
    }}>{`Example (Oslo S):`}</h3>
    <p><a parentName="p" {...{
        "href": "https://entur.no/nearby-stop-place-detail?id=NSR:StopPlace:59872"
      }}>{`https://entur.no/nearby-stop-place-detail?id=NSR:StopPlace:59872`}</a></p>
    <h3 {...{
      "id": "available-parameters"
    }}>{`Available Parameters`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`id`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NSR StopPlace ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The NSR ID of the stop place to link to`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "linking-to-a-travel-search"
    }}>{`Linking to a Travel Search`}</h2>
    <p>{`This link will start a travel search from some place to another.`}</p>
    <p>{`You can choose to either use an ID (`}<inlineCode parentName="p">{`startId`}</inlineCode>{`/`}<inlineCode parentName="p">{`stopId`}</inlineCode>{`) or coordinates (`}<inlineCode parentName="p">{`startLon`}</inlineCode>{`+`}<inlineCode parentName="p">{`startLat`}</inlineCode>{` / `}<inlineCode parentName="p">{`stopLon`}</inlineCode>{`+`}<inlineCode parentName="p">{`stopLat`}</inlineCode>{`).
If you omit either the start or stop parameters, the user's own location will be used, if available.`}</p>
    <h3 {...{
      "id": "examples-from-nationaltheatret-to-oslo-s"
    }}>{`Examples (from Nationaltheatret to Oslo S):`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{` Link`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`User's Location – Oslo S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{` `}<a parentName="td" {...{
              "href": "https://entur.no/travel-result?stopId=NSR:StopPlace:59872"
            }}>{`https://entur.no/travel-result?stopId=NSR:StopPlace:59872`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Nationaltheatret – Oslo S`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{` `}<a parentName="td" {...{
              "href": "https://entur.no/travel-result?startId=NSR:StopPlace:58404&stopId=NSR:StopPlace:59872"
            }}>{`https://entur.no/travel-result?startId=NSR:StopPlace:58404&stopId=NSR:StopPlace:59872`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Oslo S – User's Location`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{` `}<a parentName="td" {...{
              "href": "https://entur.no/travel-result?startId=NSR:StopPlace:58404"
            }}>{`https://entur.no/travel-result?startId=NSR:StopPlace:58404`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`User's Location – Coordinate`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{` `}<a parentName="td" {...{
              "href": "https://entur.no/travel-result?stopLat=59.8991327&stopLon=10.7610508"
            }}>{`https://entur.no/travel-result?stopLat=59.8991327&stopLon=10.7610508`}</a></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "available-parameters-1"
    }}>{`Available Parameters`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Type`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`startId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NSR StopPlace ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The NSR ID of the origin.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`startLat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The latitude value for the origin.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`startLon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The longitude value for the origin.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`stopId`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`NSR StopPlace ID`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The NSR ID of the destination.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`stopLat`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The latitude value for the destination.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`stopLon`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`number`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The longitude value for the destination.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`transportModes`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`comma-sep list of `}<em parentName="td">{`filters`}</em></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The transport filters to allow. If this is not supplied, all are allowed. See available filter values below.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`date`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`milliseconds since epoch (1970)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`The search time to use. If omitted, "now" is used.`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`timepickerMode`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><inlineCode parentName="td">{`departAfter`}</inlineCode>{` or `}<inlineCode parentName="td">{`arriveBefore`}</inlineCode></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Whether to search for departures leaving after `}<inlineCode parentName="td">{`date`}</inlineCode>{` (default) or for arrivals before `}<inlineCode parentName="td">{`date`}</inlineCode>{`.`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "filters"
    }}>{`Filters`}</h2>
    <p>{`Available values for the `}<inlineCode parentName="p">{`transportModes`}</inlineCode>{` parameter above.`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Filter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`bus`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`bus rides (not including airport express buses)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`tram`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`tram rides`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`rail`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`rail (train) rides (not including airport express trains)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`metro`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`metro (subway) rides`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`water`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`all kinds of transport modes on water (ferry, carferry, etc.)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`flytog`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`airport express trains`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`flybuss`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`airport express buses`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Combining these will work the same way as using the filter toggles on entur.no or in the Entur app. Provide the parameter as a comma-separated string:`}</p>
    <pre><code parentName="pre" {...{}}>{`&transportModes=bus,tram,rail
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      